'use client';
import { CalendarIcon } from 'lucide-react';
import React, { useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent, PopoverPortal } from '@radix-ui/react-popover';
import { FieldValues } from 'react-hook-form';
import { DateFormatter } from 'react-day-picker';
import { cn, formatToHumanReadable, DATE_DISPLAY_FORMAT, getMatchingLocale } from '@utils';
import { Calendar, CalendarProps } from '../calendar/calendar';
import { Button } from '../button/button';
import { Skeleton } from '../skeleton/skeleton';

// see: https://react-day-picker.js.org/api/types/Formatters
type FormatType = 'formatMonthCaption';

type Props = Omit<CalendarProps, 'locale'> & {
  field: FieldValues;
  formatters?: Record<FormatType, DateFormatter>;
  defaultDate?: Date;
  fromYear: number;
  toYear: number;
  locale: string;
  placeholder: string;
  buttonClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  ['aria-invalid']?: boolean;
};

export function DatePicker({ disabled, ...props }: Props): React.JSX.Element {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const {
    field,
    defaultDate,
    formatters,
    fromYear,
    locale,
    placeholder,
    toYear,
    buttonClassName,
    loading,
    ...calendarProps
  } = props;

  let buttonContent: React.ReactNode;
  if (loading) {
    buttonContent = <Skeleton className="h-[1em] w-[150px]" />;
  } else if (field.value) {
    buttonContent = <span>{formatToHumanReadable(field.value, locale, DATE_DISPLAY_FORMAT.DATE)}</span>;
  } else {
    buttonContent = (
      <span
        className={
          props['aria-invalid']
            ? disabled
              ? 'text-error-ternary'
              : 'text-error-secondary'
            : disabled
              ? 'text-primary-disabled'
              : 'text-subtext'
        }
      >
        {placeholder}
      </span>
    );
  }

  const defaultMonth = field.value ? field.value : defaultDate;
  return (
    <Popover open={datePickerOpen} onOpenChange={setDatePickerOpen}>
      <PopoverTrigger asChild>
        <Button
          data-testid="register-birth-date"
          variant="tertiary"
          className={cn(
            '!text-md w-[292px] max-w-none justify-between font-normal normal-case',
            props['aria-invalid']
              ? 'bg-error-background border-error text-error'
              : disabled
                ? 'text-primary-disabled border-primary-border-disabled'
                : '',
            buttonClassName,
          )}
          disabled={disabled || loading}
          RightIcon={CalendarIcon}
        >
          {buttonContent}
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent className="bg-background my-xxs font-body z-50 w-auto p-0 text-sm">
          <Calendar
            initialFocus
            classNames={{
              root: 'border-1 border-primary-border rounded-sm',
              caption_dropdowns: 'flex',
              caption_label: 'hidden',
              vhidden: 'hidden',
            }}
            captionLayout="dropdown-buttons"
            fixedWeeks
            fromYear={fromYear}
            defaultMonth={defaultMonth}
            formatters={formatters}
            locale={getMatchingLocale(locale)}
            mode="single"
            onDayClick={() => setDatePickerOpen(false)}
            onSelect={field.onChange}
            selected={field.value}
            showOutsideDays
            toYear={toYear}
            {...calendarProps}
          />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}
