'use client';
import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@utils';
import {
  Anchor,
  anchorBorderRadius,
  anchorForAngle,
  anchorPosition,
  circularBoxPath,
  CircularBoxPathOptions,
  p2c,
  p2x,
  p2y,
  rounded,
} from './utils';

export type RadialSegmentProps = React.SVGAttributes<SVGPathElement> & {
  innerRadius: number;
  outerRadius: number;
  angle: number;
};

/**
 * Draw a radial line between two radii, at a given angle (useful to link blocks to labels for example)
 * @param innerRadius: the inner radius of the line
 * @param outerRadius: the outer radius of the line
 * @param angle: the angle of the line
 * @returns a svg path that draws the radial line
 */
export function RadialSegment({ innerRadius, outerRadius, angle, ...props }: RadialSegmentProps): React.JSX.Element {
  return <path d={`M${p2c(innerRadius, angle)} L${p2c(outerRadius, angle)}`} {...props} />;
}

export type AutoSizedForeignObjectProps = React.SVGAttributes<SVGForeignObjectElement> & {
  x: number;
  y: number;
  anchor?: Anchor;
  rotation?: number;
  className?: string;
  children: React.ReactNode;
};

/**
 * Embed a react node in the svg, at a given location
 * The node can be positionned relative to one of its anchors, and it can be rotated relative to its center
 * The node is contained in a div that's used to display borders around the node: if this div has rounded corners, it will not be rounded at the anchor if it's in a corner
 *
 * The dimensions of the svg foreign object are adjusted automatically but it comes with some limitations:
 *   * the text wrapping must be done "manually" (using a fixed width or by breaking lines)
 *   * the node will not be displayed before hydration (it initially have zero width and height)
 *   * the hot reloading may not work properly but it doesn't impact the final result
 *
 * @param x: the x position of the anchor in the viewport
 * @param y: the y position of the anchor in the viewport
 * @param anchor: the anchor
 * @param rotation: optionally rotate the node by a given angle, in degrees, clockwise
 * @param children: react children
 * @param className: additionnal classes to apply to the div containing the node
 * @returns an svg foreignObject whose dimensions are automatically set to contain the node
 */
export function AutoSizedForeignObject({
  x,
  y,
  anchor = Anchor.CENTER,
  rotation = 0,
  children,
  className,
  ...props
}: AutoSizedForeignObjectProps): React.JSX.Element {
  const foreignObjectRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [angle, setAngle] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (foreignObjectRef.current) {
      const rect = foreignObjectRef.current.getBoundingClientRect();
      setWidth(Math.floor(rect.width) + 1);
      setHeight(Math.floor(rect.height) + 1);
      setAngle(rotation);
    }
  }, [rotation]);

  const { x: anchorX, y: anchorY } = anchorPosition(width, height, anchor);

  return (
    <foreignObject
      x={rounded(x - width / 2 - anchorX)}
      y={rounded(y - height / 2 + anchorY)}
      width={width}
      height={height}
      transform={angle ? `rotate(${angle} ${x} ${y})` : undefined}
      {...props}
    >
      <div className={cn('h-fit w-fit', anchorBorderRadius(anchor), className)} ref={foreignObjectRef}>
        {children}
      </div>
    </foreignObject>
  );
}
AutoSizedForeignObject.displayName = 'AutoSizedForeignObject';

export type HolygraphLabelProps = {
  radius: number;
  angle: number;
  className?: string;
  children: React.ReactNode;
};

/**
 * Display a node at a given angle and radius.
 * The anchor is automatically chosen to display the node away from the center.
 * The node is contained in a div that's used to display borders around the node: if this div has rounded corners, it will not be rounded at the anchor if it's in a corner.
 * This is ideal to display a label at the end of a radial segment.
 * @param radius: the radius of the anchor
 * @param angle: the angle of the anchor
 * @param className: additionnal classes to apply to the div containing the node
 * @returns an svg foreignObject whose dimensions are automatically set to contain the node
 */
export function HolygraphLabel({ radius, angle, className, children }: HolygraphLabelProps): React.JSX.Element {
  const anchor = anchorForAngle(angle);

  return (
    <AutoSizedForeignObject x={p2x(radius, angle)} y={p2y(radius, angle)} anchor={anchor} className={className}>
      {children}
    </AutoSizedForeignObject>
  );
}

export type HolygraphBoxProps = React.SVGAttributes<SVGPathElement> &
  CircularBoxPathOptions & {
    className?: string;
  };

/**
 * Display a "circular box" with rounded corners.
 * The side margin should be equal to half the radial distance between two blocks
 * @param innerRadius: inner radius of the box
 * @param outerRadius: outer radius of the box
 * @param startAngle: the angle at the start of the box
 * @param endAngle: the angle at the end of the box
 * @param innerStartCornerRadius: radius of the inner start corner
 * @param outerStartCornerRadius: radius of the outer start corner
 * @param innerEndCornerRadius: radius of the inner end corner
 * @param outerEndCornerRadius: radius of the outer end corner
 * @param sideMargin: the margin, in pixels, we'd like to have on both "start" and "end" sides - it will automatically be converted to angles
 * @param className: tailwind classes to apply to the path - useful to give it a stroke and fill colors, a hover state etc.
 * @returns svg path representing the box
 */
export const HolygraphBox = React.forwardRef<SVGPathElement, HolygraphBoxProps>(
  (
    {
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      innerStartCornerRadius,
      outerStartCornerRadius,
      innerEndCornerRadius,
      outerEndCornerRadius,
      sideMargin,
      className,
      ...svgProps
    },
    ref,
  ) => {
    const path = circularBoxPath({
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      innerStartCornerRadius,
      outerStartCornerRadius,
      innerEndCornerRadius,
      outerEndCornerRadius,
      sideMargin,
    });
    // The path is drawn clockwise
    return <path d={path} className={className} ref={ref} {...svgProps} />;
  },
);
HolygraphBox.displayName = 'HolygraphBox';

/**
 * Display a "circular box" with rounded corners, with accent gradient
 * The side margin should be equal to half the radial distance between two blocks
 * @param id: a unique identifier
 * @param full: if true, then the block is filled with a gradient. Otherwise, just the border is displayed
 * @param innerRadius: inner radius of the box
 * @param outerRadius: outer radius of the box
 * @param startAngle: the angle at the start of the box
 * @param endAngle: the angle at the end of the box
 * @param innerStartCornerRadius: radius of the inner start corner
 * @param outerStartCornerRadius: radius of the outer start corner
 * @param innerEndCornerRadius: radius of the inner end corner
 * @param outerEndCornerRadius: radius of the outer end corner
 * @param sideMargin: the margin, in pixels, we'd like to have on both "start" and "end" sides - it will automatically be converted to angles
 * @returns svg path representing the box with gradients
 */
export const GradientHolygraphBox = React.forwardRef<
  SVGPathElement,
  HolygraphBoxProps & { id: string; full?: boolean }
>(
  (
    {
      id,
      full = false,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      innerStartCornerRadius,
      outerStartCornerRadius,
      innerEndCornerRadius,
      outerEndCornerRadius,
      sideMargin,
    },
    ref,
  ) => {
    const path = circularBoxPath({
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      innerStartCornerRadius,
      outerStartCornerRadius,
      innerEndCornerRadius,
      outerEndCornerRadius,
      sideMargin,
    });
    return (
      <>
        <defs>
          <linearGradient id={`${id}-gradient`} gradientTransform={`rotate(${(endAngle + startAngle) / 2})`}>
            <stop offset="0%" stopColor="var(--accent-gradient-from)" />
            <stop offset="100%" stopColor="var(--accent-gradient-to)" />
          </linearGradient>
          {full ? (
            <></>
          ) : (
            <mask id={`${id}-border-mask`}>
              <path d={path} stroke="white" fill="none" strokeWidth="2" />
            </mask>
          )}
        </defs>
        {full ? (
          <path d={path} className="stroke-none" fill={`url(#${id}-gradient)`} ref={ref} />
        ) : (
          <>
            {/* svg cannot apply a gradient directly to a stroke, therefore we use the stroke as a mask */}
            <path d={path} fill={`url(#${id}-gradient)`} mask={`url(#${id}-border-mask)`} ref={ref} />
          </>
        )}
      </>
    );
  },
);
GradientHolygraphBox.displayName = 'GradientHolygraphBox';

export type HolygraphProps = React.SVGAttributes<SVGSVGElement> & {
  width: number;
  height: number;
  className?: string;
  children: React.ReactNode;
};

/**
 * This is the main container for a holygraph
 * It is simply an svg with a given width and height
 * The zero is in the center
 * @param width: the viewport width (should be able to contain all the elements in the holygraph)
 * @param height: the viewport height (should be able to contain all the elements in the holygraph)
 * @param className: additionnal classes to apply to the svg
 * @param children: react children
 * @returns an svg to contain the elements of the holygraph
 */
export const Holygraph = React.forwardRef<SVGSVGElement, HolygraphProps>(
  ({ width, height, className, children }, ref) => {
    return (
      <svg
        className={className}
        viewBox={`-${width / 2} -${height / 2} ${width} ${height}`}
        width={`${width}px`}
        height={`${height}px`}
        ref={ref}
      >
        {children}
      </svg>
    );
  },
);
Holygraph.displayName = 'Holygraph';
