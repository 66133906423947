import { CookieOptions } from '@core-systems/cookies';
import getI18nConfiguration from '../configuration';

/*
 * WARNING: this function mutates its res argument
 */
export function setCookieOnResponse(locale: string): Record<string, { value: string; options: CookieOptions }> {
  const configuration = getI18nConfiguration();
  const setCookies = {
    [configuration.cookie.name]: {
      value: locale,
      options: configuration.cookie.options,
    },
  };
  return setCookies;
}
