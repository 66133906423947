'use client';

import { ChevronLeft } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { cn } from '@utils';
import { Button } from '../button/button';

export const BackButton = ({ className, backUrl }: { className?: string; backUrl?: string }): React.JSX.Element => {
  const router = useRouter();

  return (
    <Button
      variant="secondary"
      Icon={ChevronLeft}
      className={cn('rtl:rotate-180', className)}
      href={backUrl}
      onClick={() => (backUrl ? null : router.back())}
    />
  );
};
