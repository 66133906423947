'use client';
import Cropper from 'react-easy-crop';
import { useState } from 'react';
import { Check } from 'lucide-react';
import { DropzoneProps } from 'react-dropzone';
import { useClientTranslations } from '@core-systems/i18n';
import { formatBytes } from '@utils';
import { Button } from '../button/button';
import { FileUpload } from '../file-upload/file-upload';
import getCroppedImage, { CroppingArea } from './image-crop';

interface FileUploadCropperProps extends TranslatedFileUploadCropperProps {
  /**
   * Error message for multiple files.
   * @type string
   * @default undefined
   * @example errorMessageMultipleFiles="You can only upload one file at a time"
   */
  errorMessageMultipleFiles: string;

  /**
   * Error message for maximum files.
   * @type string
   * @default undefined
   * @example errorMessageMaxFiles="You can only upload 4 files"
   */
  errorMessageMaxFiles: string;

  /**
   * Tip message for maximum uploadable files.
   * @type string
   * @default undefined
   * @example messageMaxUploadableFiles="You can upload up to 4 files"
   */
  messageMaxUploadableFiles: string;

  /**
   * Tip message for maximum uploadable size.
   * @type string
   * @default undefined
   * @example messageMaxUploadableSize="File size limit is 2MB"
   */
  messageMaxUploadableSize: string;
}

const DEFAULT_MAX_SIZE = 1024 * 1024 * 2;
const DEFAULT_MAX_FILES = 1;

export const FileUploadCropper = (props: FileUploadCropperProps): React.JSX.Element => {
  const [image, setImage] = useState<(File & { preview: string }) | null>(null);
  const [croppedArea, setCroppedArea] = useState<CroppingArea | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onImageUpload = (event: React.FormEvent<HTMLInputElement>): void => {
    const value = (event as unknown as { target: { value: (File & { preview: string })[] } }).target.value;

    // Manage onRemove
    if (!value || !props.value || value.length < props.value.length) {
      props.onChange({ target: { value } } as unknown as React.FormEvent<HTMLInputElement>);
      return;
    }

    const [uploadedImage] = value.slice(-1);
    setImage(uploadedImage);
  };

  const onCropComplete = (_: unknown, updatedCroppedArea: CroppingArea): void => {
    setCroppedArea(updatedCroppedArea);
  };

  const onImageCropped = async (): Promise<void> => {
    try {
      if (!image?.preview || !croppedArea) {
        return;
      }

      const croppedImage = await getCroppedImage(image.preview, croppedArea, image.type);

      if (!croppedImage) {
        return;
      }

      const file = Object.assign(new File([croppedImage], image.name, { type: image.type, lastModified: Date.now() }), {
        preview: URL.createObjectURL(croppedImage),
      });

      const updatedValue = (props.value || []).concat(file);
      props.onChange({ target: { value: updatedValue } } as unknown as React.FormEvent<HTMLInputElement>);
      setImage(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedArea(null);
    } catch (error) {
      // ? Do nothing
    }
  };

  return (
    <div className="relative">
      {image && (
        <div className={`h-[${props.cropperHeight || 300}px]`}>
          <Cropper
            image={image.preview}
            crop={crop}
            zoom={zoom}
            aspect={props.aspectRatio || 1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            showGrid={false}
            style={{ containerStyle: { height: props.cropperHeight || 300 } }}
          />
          <Button
            onClick={onImageCropped}
            variant="tertiary"
            type="button"
            className="py-xxs px-xxs !absolute bottom-[0.25rem] end-[0.25rem]"
          >
            <Check />
          </Button>
        </div>
      )}

      <FileUpload
        multiple={false}
        accept={{ 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] }}
        {...props}
        onChange={onImageUpload}
        className={image ? 'hidden' : ''}
      />
    </div>
  );
};

interface TranslatedFileUploadCropperProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The value of the uploader.
   * @type File[]
   * @default undefined
   * @example value={files}
   */
  value: File[];

  /**
   * Callback fired when the value changes.
   * @type (files: File[]) => void
   * @default undefined
   */
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;

  /**
   * Maximum file size for the uploader.
   * @type number | undefined
   * @default 1024 * 1024 * 2 // 2MB
   * @example maxSize={1024 * 1024 * 2} // 2MB
   */
  maxSize?: DropzoneProps['maxSize'];

  /**
   * Maximum number of files for the uploader.
   * @type number | undefined
   * @default 1
   * @example maxFiles={4}
   */
  maxFiles?: DropzoneProps['maxFiles'];

  /**
   * Placeholder for the uploader.
   * @type string
   * @default undefined
   * @example placeholder="Drag & drop some files here, or click to select files"
   */
  placeholder?: string;

  /**
   * Whether tips relative to number of files and max size are displayed.
   * @type boolean
   * @default true
   * @example withTips=true
   */
  withTips?: boolean;

  /**
   * Whether the uploader should show a preview of the file.
   * @type boolean
   * @default true
   * @example withPreview=true
   */
  withPreview?: boolean;

  /**
   * Whether the uploader is disabled.
   * @type boolean
   * @default false
   * @example disabled
   */
  disabled?: boolean;

  /**
   * Cropper height in pixels.
   * @type number
   * @default 300
   * @example 460
   */
  cropperHeight?: number;

  /**
   * Aspect ratio for image.
   * @type number
   * @default 1
   * @example 4 / 3
   */
  aspectRatio?: number;
}

export const TranslatedFileUploadCropper = (props: TranslatedFileUploadCropperProps): React.JSX.Element => {
  const { t } = useClientTranslations('common');
  return (
    <FileUploadCropper
      errorMessageMultipleFiles={t('file-upload.errors.multiple-files')}
      errorMessageMaxFiles={t('file-upload.errors.max-files', { maxFiles: props.maxFiles ?? DEFAULT_MAX_FILES })}
      messageMaxUploadableFiles={t('file-upload.tips.max-files', { maxFiles: props.maxFiles ?? DEFAULT_MAX_FILES })}
      messageMaxUploadableSize={t('file-upload.tips.max-size', {
        maxSize: formatBytes(props.maxSize ?? DEFAULT_MAX_SIZE),
      })}
      {...props}
    />
  );
};
