'use client';

import React from 'react';
import { AlertTriangle, CircleCheck, CircleX, InfoIcon } from 'lucide-react';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './toast';
import { useToast } from './use-toast';

const DEFAULT_DURATION = 10 * 1000;
export function Toaster(): React.JSX.Element {
  const { toasts } = useToast();
  const iconSize = 16;

  return (
    <ToastProvider>
      {toasts.map(function ({ id, variant, duration, title, description, action, ...props }) {
        return (
          <Toast key={id} variant={variant} duration={duration || DEFAULT_DURATION} {...props}>
            <div className="gap-xs flex flex-col">
              <div className="gap-xs flex flex-row items-center">
                {variant === 'info' && <InfoIcon size={iconSize} />}
                {variant === 'error' && <CircleX className="text-error-primary" size={iconSize} />}
                {variant === 'warning' && <AlertTriangle className="text-warning-primary" size={iconSize} />}
                {variant === 'success' && <CircleCheck className="text-success-primary" size={iconSize} />}
                {title && (
                  <ToastTitle data-testid="toast-title" className="me-sm">
                    {title}
                  </ToastTitle>
                )}
              </div>
              {description && <ToastDescription data-testid="toast-content">{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
