import { cn } from '@utils';

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>): React.JSX.Element {
  return (
    <div
      className={cn(
        'bg-secondary-background-transparent relative isolate overflow-hidden rounded-sm before:absolute before:inset-0',
        'rtl:before:animate-shine-reverse before:animate-shine before:via-white-500 before:bg-gradient-to-r before:from-transparent before:to-transparent',
        className,
      )}
      {...props}
    />
  );
}

export { Skeleton };
