import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@utils';
import { LucideProps } from 'lucide-react';

const badgeVariants = cva(
  'inline-flex items-center rounded-sm px-xs py-2xs text-sm font-normal w-fit h-fit bg-background`',
  {
    variants: {
      variant: {
        default: 'bg-badge-primary text-primary',
        success: 'bg-success-quinary text-success',
        secondary: 'bg-secondary-background text-primary bg-secondary-background',
        warning: 'bg-warning-quaternary text-warning',
        error: 'bg-error-quinary text-error',
        disabled: 'bg-badge-primary-disabled text-subtext',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  RightIcon?: React.FC<LucideProps>;
}

function Badge({ className, variant, RightIcon, children, ...props }: BadgeProps): React.JSX.Element {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props}>
      <span className="flex-1 truncate">{children}</span>
      {RightIcon ? <RightIcon className="ms-xxs size-[1em] flex-shrink" /> : null}
    </div>
  );
}

export { Badge, badgeVariants };
