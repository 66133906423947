'use client';
import { Info, LucideProps } from 'lucide-react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';
import { cn } from '@utils';

const TooltipTriggerInfoIcon = React.forwardRef<SVGSVGElement, Omit<LucideProps, 'ref'>>(
  ({ className, ...props }, ref) => (
    <TooltipPrimitive.Trigger asChild>
      <Info
        ref={ref}
        className={cn(
          'fill-foreground text-background focusable absolute end-0 top-0 h-[1.25rem] w-[1.25rem] cursor-help rounded-full',
          className,
        )}
        tabIndex={0}
        {...props}
      />
    </TooltipPrimitive.Trigger>
  ),
);

TooltipTriggerInfoIcon.displayName = TooltipPrimitive.Trigger.displayName;

export { TooltipTriggerInfoIcon };
