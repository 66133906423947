const defaultCornerRadius = 8;
const defaultSideMargin = 4;

export enum Anchor {
  CENTER = 'center',
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

/**
 * Round a number to the 6th decimal place (useful to avoid hydration errors due to double precision errors)
 * @param n: a real number
 * @returns the same number rounded to 6th decimal place
 */
export const rounded = (n: number): number => Math.round(n * 1000000) / 1000000;

/**
 * Find th ebest anchor of a label depending on its angle relative to the center of the holygraph
 * The angle is running clockwise, starting from the top direction
 * @param angle: the angle of the label
 * @returns the ideal anchor to position the label relative to a radial line
 */
export function anchorForAngle(angle: number | undefined): Anchor {
  if (angle === undefined) return Anchor.CENTER;
  if (angle === 0) return Anchor.BOTTOM;
  if (angle === 90) return Anchor.LEFT;
  if (angle === 180) return Anchor.TOP;
  if (angle === 270) return Anchor.RIGHT;
  if (angle > 0 && angle < 90) return Anchor.BOTTOM_LEFT;
  if (angle > 90 && angle < 180) return Anchor.TOP_LEFT;
  if (angle > 180 && angle < 270) return Anchor.TOP_RIGHT;
  if (angle > 270 && angle < 360) return Anchor.BOTTOM_RIGHT;
  return Anchor.CENTER;
}

/**
 * Shift a rectangle to position it relative to one of its anchors
 * @param width: width of the rectangle
 * @param height: height of the rectangle
 * @param anchor: the anchor of the rectangle that should be at the current position
 * @returns: the distance to move the rectangle in x and y directions, to position it relative to the anchor
 */
export function anchorPosition(width: number, height: number, anchor: Anchor): { x: number; y: number } {
  if (anchor === Anchor.CENTER) return { x: 0, y: 0 };
  if (anchor === Anchor.BOTTOM) return { x: 0, y: -height / 2 };
  if (anchor === Anchor.LEFT) return { x: -width / 2, y: 0 };
  if (anchor === Anchor.TOP) return { x: 0, y: height / 2 };
  if (anchor === Anchor.RIGHT) return { x: width / 2, y: 0 };
  if (anchor === Anchor.BOTTOM_LEFT) return { x: -width / 2, y: -height / 2 };
  if (anchor === Anchor.TOP_LEFT) return { x: -width / 2, y: height / 2 };
  if (anchor === Anchor.TOP_RIGHT) return { x: width / 2, y: height / 2 };
  if (anchor === Anchor.BOTTOM_RIGHT) return { x: width / 2, y: -height / 2 };
  throw new Error('Invalid anchor');
}

/**
 * Automatic tailwind class to cancel the rounding of the border at the anchor, if it's in a corner
 * @param anchor: the anchor of a label
 * @returns: the class to apply to the label to cancel rounding on the anchor, if it's in a corner
 */
export function anchorBorderRadius(anchor: Anchor): string {
  if (anchor === Anchor.BOTTOM_LEFT) return '!rounded-bl-none';
  if (anchor === Anchor.TOP_LEFT) return '!rounded-tl-none';
  if (anchor === Anchor.TOP_RIGHT) return '!rounded-tr-none';
  if (anchor === Anchor.BOTTOM_RIGHT) return '!rounded-br-none';
  return '';
}

/**
 * Convert polar coordinates to cartesian x
 * @param r distance from origin
 * @param a clockwise angle relative to the top direction
 * @returns the x position of the vertex in the viewbox
 */
export const p2x = (r: number, a: number): number => rounded(r * Math.sin((a * Math.PI) / 180));

/**
 * Convert polar coordinates to cartesian y
 * @param r distance from origin
 * @param a clockwise angle relative to the top direction
 * @returns the y position of the vertex in the viewbox
 */
export const p2y = (r: number, a: number): number => rounded(-r * Math.cos((a * Math.PI) / 180));

/**
 * Convert polar coordinates to cartesian
 * @param r distance from origin
 * @param a clockwise angle relative to the top direction
 * @returns a string `x y` representing the position of the vertex in the viewbox
 */
export const p2c = (r: number, a: number): string => `${p2x(r, a)} ${p2y(r, a)}`;

/**
 * Svg path of an arc of a given radius from the current position to a new angular position
 * @param r: radius of the arc
 * @param clockwise: true if rotating clockwise, false if counter-clockwise
 * @param long: true if the arc spans more than 180 degrees
 * @param endAngle the angle at the end of the arc
 * @returns svg path of the arc
 */
export const arc = (r: number, clockwise: boolean, long: boolean, endAngle: number): string =>
  `A${r} ${r} 0 ${long ? 1 : 0} ${clockwise ? 1 : 0} ${p2c(r, endAngle)} `;

/**
 * Estimate the angle covered by an ortho-radial segment of a given length, at a given radius
 * It is useful to have an even space between blocks, in both radial and orthoradial directions
 * @param radius: the distance from the center
 * @param length: the length of an ortho-radial segment
 * @returns: the angular length of the segment, as seen from the center
 */
export function angularPortion(radius: number, length: number): number {
  const perimeter = radius * 2 * Math.PI;
  return rounded((length / perimeter) * 360);
}

export type CircularBoxPathOptions = {
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  innerStartCornerRadius?: number;
  outerStartCornerRadius?: number;
  innerEndCornerRadius?: number;
  outerEndCornerRadius?: number;
  sideMargin?: number;
};

/**
 * Svg path of a circular box with rounded corners
 * The side margin should be equal to half the radial distance between two blocks
 * @param innerRadius: inner radius of the box
 * @param outerRadius: outer radius of the box
 * @param startAngle: the angle at the start of the box
 * @param endAngle: the angle at the end of the box
 * @param innerStartCornerRadius: radius of the inner start corner
 * @param outerStartCornerRadius: radius of the outer start corner
 * @param innerEndCornerRadius: radius of the inner end corner
 * @param outerEndCornerRadius: radius of the outer end corner
 * @param sideMargin: the margin, in pixels, we'd like to have on both "start" and "end" sides - it will automatically be converted to angles
 * @returns svg path of the box
 */
export function circularBoxPath({
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  innerStartCornerRadius = defaultCornerRadius,
  outerStartCornerRadius = defaultCornerRadius,
  innerEndCornerRadius = defaultCornerRadius,
  outerEndCornerRadius = defaultCornerRadius,
  sideMargin = defaultSideMargin,
}: CircularBoxPathOptions): string {
  if (outerRadius <= innerRadius) throw new Error('Outer radius must be greater than inner radius');
  if (endAngle <= startAngle) throw new Error('End angle must be greater than start angle');

  // Inner start corner
  const innerStartAngle = startAngle + angularPortion(innerRadius, sideMargin);
  const innerStartCornerBefore = p2c(
    innerRadius,
    innerStartAngle + angularPortion(innerRadius, innerStartCornerRadius),
  );
  const innerStartCornerTop = p2c(innerRadius, innerStartAngle);
  const innerStartCornerAfter = p2c(innerRadius + innerStartCornerRadius, innerStartAngle);

  // Outer start corner
  const outerStartAngle = startAngle + angularPortion(outerRadius, sideMargin);
  const outerStartCornerBefore = p2c(outerRadius - outerStartCornerRadius, outerStartAngle);
  const outerStartCornerTop = p2c(outerRadius, outerStartAngle);
  const outerStartCornerAfter = p2c(outerRadius, outerStartAngle + angularPortion(outerRadius, outerStartCornerRadius));

  // Outer end corner
  const outerEndAngle = endAngle - angularPortion(outerRadius, sideMargin);
  const outerEndCornerBefore = p2c(outerRadius, outerEndAngle - angularPortion(outerRadius, outerEndCornerRadius));
  const outerEndCornerTop = p2c(outerRadius, outerEndAngle);
  const outerEndCornerAfter = p2c(outerRadius - outerEndCornerRadius, outerEndAngle);

  // Inner end corner
  const innerEndAngle = endAngle - angularPortion(innerRadius, sideMargin);
  const innerEndCornerBefore = p2c(innerRadius + innerEndCornerRadius, innerEndAngle);
  const innerEndCornerTop = p2c(innerRadius, innerEndAngle);
  const innerEndCornerAfter = p2c(innerRadius, innerEndAngle - angularPortion(innerRadius, innerEndCornerRadius));

  // The path is drawn clockwise, with 3 points per corner
  return `
    M${innerStartCornerBefore}
    Q${innerStartCornerTop} ${innerStartCornerAfter}
    L${outerStartCornerBefore}
    Q${outerStartCornerTop} ${outerStartCornerAfter}
    ${arc(outerRadius, true, outerEndAngle - outerStartAngle - 2 * angularPortion(outerRadius, outerEndCornerRadius) > 180, outerEndAngle - angularPortion(outerRadius, outerEndCornerRadius))}
    L${outerEndCornerBefore}
    Q${outerEndCornerTop} ${outerEndCornerAfter}
    L${innerEndCornerBefore}
    Q${innerEndCornerTop} ${innerEndCornerAfter}
    ${arc(innerRadius, false, outerEndAngle - outerStartAngle - 2 * angularPortion(innerRadius, innerStartCornerRadius) > 180, innerStartAngle + angularPortion(innerRadius, innerStartCornerRadius))}
    Z
  `;
}
