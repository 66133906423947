'use client';

import * as React from 'react';
import { ChevronDown, Check, X } from 'lucide-react';
import { cn } from '@utils';
import { Button } from '../button/button';
import { Badge } from '../badge/badge';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../command/command';
import { Popover, PopoverContent, PopoverTrigger } from '../popover/popover';
import { Separator } from '../separator/separator';

/**
 * Props for MultiSelect component
 */
export interface MultiSelectProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * An array of option objects to be displayed in the multi-select component.
   * Each option object has a label and value.
   */
  options: {
    /** The text to display for the option. */
    label: string;
    /** The unique value associated with the option. */
    value: string;
  }[];

  /**
   * Callback function triggered when the selected values change.
   * Receives an array of the new selected values.
   */
  onValueChange: (value: string[]) => void;

  /** The default selected values when the component mounts. */
  defaultValue?: string[];

  /**
   * Placeholder text to be displayed when no values are selected.
   */
  placeholder: string;

  /**
   * Placeholder text to be displayed for options search input.
   */
  searchPlaceholder: string;

  /**
   * A component to be displayed for no results found.
   */
  noResultFound: React.ReactElement | string;

  /**
   * A text to be displayed for more items badge if max selected.
   */
  moreLabel: string;

  /**
   * A text to be displayed for the clear button
   */
  clearLabel: string;

  /**
   * A text to be displayed for the close button
   */
  closeLabel: string;

  /**
   * Maximum number of items to display. Extra selected items will be summarized.
   * Optional, defaults to 3.
   */
  maxCount?: number;

  /**
   * The modality of the popover. When set to true, interaction with outside elements
   * will be disabled and only popover content will be visible to screen readers.
   * Optional, defaults to false.
   */
  modalPopover?: boolean;

  /**
   * If true, renders the multi-select component as a child of another component.
   * Optional, defaults to false.
   */
  asChild?: boolean;

  /**
   * Whether the input is disabled or not
   * Optional, default to false.
   */
  disabled?: boolean;

  /**
   * Additional class names to apply custom styles to the multi-select component.
   * Optional, can be used to add custom styles.
   */
  className?: string;
}

export const MultiSelect = React.forwardRef<HTMLDivElement, MultiSelectProps>(
  (
    {
      options = [],
      onValueChange,
      defaultValue = [],
      placeholder,
      searchPlaceholder,
      noResultFound,
      moreLabel,
      clearLabel,
      closeLabel,
      maxCount = 3,
      modalPopover = false,
      asChild = false,
      className,
      ...props
    },
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = React.useState<string[]>(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        setIsPopoverOpen(true);
      } else if (event.key === 'Backspace' && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (option: string): string[] => {
      const newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value !== option)
        : [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
      return newSelectedValues;
    };

    const handleClear = (): boolean => {
      setSelectedValues([]);
      onValueChange([]);
      return true;
    };

    const handleClick = (): void => {
      return setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = (): void => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const ChevronButton = (
      <Button
        Icon={ChevronDown}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleClick();
        }}
        className={cn('ms-2 !p-0', props['aria-invalid'] ? 'text-error-primary' : 'text-foreground')}
        variant="quaternary"
      />
    );

    return (
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={modalPopover}>
        <PopoverTrigger asChild>
          <div
            onClick={handleClick}
            className={cn(
              'focusable bg-foreground text-md py-xs flex min-h-11 flex-row items-center justify-between border px-3 hover:bg-inherit [&>span]:line-clamp-1 [&_svg]:pointer-events-auto',
              'hover:border-primary-border-hover',
              props['aria-invalid']
                ? 'bg-error-background border-error text-error placeholder:text-error-secondary disabled:placeholder:text-error-tertiary data-[placeholder]:text-error-secondary disabled:data-[placeholder]:text-error-tertiary'
                : 'bg-background border-primary-border placeholder:text-subtext data-[placeholder]:text-subtext text-primary',
              '[&[data-state=open]_svg.lucide-chevron-down]:rotate-180',
              'disabled:border-primary-border-disabled disabled:text-primary-disabled disabled:placeholder:text-primary-disabled [&:disabled_svg.lucide-chevron-down]:text-primary-border-disabled disabled:cursor-not-allowed',
              className,
            )}
            ref={ref}
            tabIndex={0}
            {...props}
          >
            {selectedValues.length > 0 ? (
              <div className="flex w-full items-center justify-between">
                <div className="gap-xxs flex flex-wrap items-center">
                  {selectedValues.slice(0, maxCount).map((value) => {
                    const option = options.find((o) => o.value === value);

                    return (
                      <Badge
                        key={value}
                        className="border-primary-disabled leading-sm max-w-20 !px-1 text-sm"
                        RightIcon={() => (
                          <DeleteButton
                            onClick={() => {
                              toggleOption(value);
                            }}
                          />
                        )}
                      >
                        <span className="truncate text-ellipsis">{option?.label}</span>
                      </Badge>
                    );
                  })}
                  {selectedValues.length > maxCount && (
                    <Badge
                      variant="secondary"
                      className="leading-sm !px-1 text-sm"
                      RightIcon={() => <DeleteButton onClick={clearExtraOptions} />}
                    >
                      {`+ ${selectedValues.length - maxCount} ${moreLabel}`}
                    </Badge>
                  )}
                </div>
                <div className="flex items-center self-stretch">
                  <Button
                    className="mx-2 !p-0"
                    variant="quaternary"
                    Icon={X}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClear();
                    }}
                  />
                  <Separator orientation="vertical" className="h-full min-h-6" />
                  {ChevronButton}
                </div>
              </div>
            ) : (
              <div className="mx-auto flex w-full items-center justify-between">
                <span className={cn('text-subtext', props['aria-invalid'] && 'text-error-secondary')}>
                  {placeholder}
                </span>
                {ChevronButton}
              </div>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className={cn('p-xs border-primary-border min-w-[var(--radix-popper-anchor-width) max-w-[500px]] !w-auto')}
          align="start"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            <CommandInput
              disableFocusStyle={true}
              placeholder={searchPlaceholder}
              onKeyDown={handleInputKeyDown}
              outlineClassName="border-card-border"
              className="placeholder:text-subtext"
            />
            <CommandList className="!border-0 !p-0">
              <CommandEmpty>{noResultFound}</CommandEmpty>
              <CommandGroup className="[&_[cmdk-group-items]]:gap-none">
                {options.map((option) => {
                  const isSelected = selectedValues.includes(option.value);
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      className="text-md cursor-pointer !gap-0"
                    >
                      <div
                        className={cn(
                          'border-primary-disabled me-2 flex items-center justify-center rounded-sm border',
                          isSelected ? 'bg-foreground text-background' : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <Check className="size-[14px]" />
                      </div>
                      <span className="truncate text-ellipsis">{option.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <Separator className="mt-xs mb-xs" />
              <div>
                <div className="gap-xxs flex items-center justify-between">
                  {selectedValues.length > 0 && (
                    <Button onClick={handleClear} className="flex-1" variant="destructive">
                      {clearLabel}
                    </Button>
                  )}
                  <Button className="flex-1" variant="secondary" onClick={() => setIsPopoverOpen(false)}>
                    {closeLabel}
                  </Button>
                </div>
              </div>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

MultiSelect.displayName = 'MultiSelect';

export type DeleteButtonProps = {
  onClick: (e: React.MouseEvent) => void;
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick }) => (
  <Button
    className="ms-1 !p-0"
    variant="quaternary"
    onClick={(event) => {
      event.stopPropagation();
      onClick(event);
    }}
    Icon={() => <X className="h-3.5 w-3.5" />}
  />
);

DeleteButton.displayName = 'DeleteButton';
