'use client';

import * as TogglePrimitive from '@radix-ui/react-toggle';
import { cn } from '@utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const toggleVariants = cva(
  'inline-flex items-center bg-background justify-center text-sm font-medium focusable transition-colors',
  {
    variants: {
      variant: {
        primary: cn(
          'font-bold text-base',
          'bg-transparent text-primary border border-transparent',
          'hover:border hover:border-primary hover:text-black hover:bg-primary-background',
          'data-[state=on]:border data-[state=on]:border-primary data-[state=on]:text-black data-[state=on]:bg-primary-background',
          'data-[state=on]:hover:border',
        ),
        success: cn(
          'uppercase',
          'bg-secondary-background text-primary border border-secondary-border',
          'hover:border-primary',
          'data-[state=on]:bg-success-background data-[state=on]:text-success data-[state=on]:border data-[state=on]:border-success-border',
          'data-[state=on]:hover:border-success',
        ),
        destructive: cn(
          'uppercase',
          'bg-secondary-background text-primary border border-secondary-border',
          'hover:border-primary',
          'data-[state=on]:bg-error-background data-[state=on]:text-error data-[state=on]:border data-[state=on]:border-error-border',
          'data-[state=on]:hover:border-error',
        ),
      },
      size: {
        default: 'h-10 px-3',
        sm: 'h-9 px-2.5',
        lg: 'h-11 px-5',
      },
    },
    defaultVariants: {
      variant: 'success',
      size: 'default',
    },
  },
);

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root ref={ref} className={cn(toggleVariants({ variant, size, className }))} {...props} />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
