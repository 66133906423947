'use client';

import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@utils';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => {
  return (
    <SwitchPrimitives.Root
      className={cn(
        'focusable bg-secondary-background peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center border-2 border-transparent transition-colors',
        'data-[state=checked]:disabled:bg-primary-disabled disabled:cursor-not-allowed',
        'data-[state=checked]:bg-success',
        className,
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'bg-primary h-md w-md pointer-events-none block rounded-sm shadow-lg transition-transform',
          'data-[state=checked]:bg-primary data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0',
          'rtl:data-[state=checked]:-translate-x-4',
          'data-[state=unchecked]:data-[disabled]:bg-primary-disabled',
        )}
      />
    </SwitchPrimitives.Root>
  );
});

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
