export { useClientTranslations, type TranslationFunction } from './client/use-translation.hook';
export { useClientLocale } from './client/use-locale.hook';
export { serverTranslations } from './server/server-translations';
export { supportedLocales, supportedTranslations } from './services/i18n.services';
export { localeFromRequest } from './server/locale/locale-from-request';
export { MiddlewareLocale } from './server/locale/middleware-locale';
export { setCookieOnResponse } from './server/set-cookie-on-response';
export { getTranslationMessages } from './server/get-translation-messages';
export { TranslationsProvider } from './client/translations.provider';
export { type PropsWithLocale } from './locales/props-with-locale';
export { getTextDirection } from './services/i18n.services';
import getI18nConfiguration from './configuration';

export { i18nConfigSchema } from './configuration';

export { getI18nConfiguration };
