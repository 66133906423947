'use client';

import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@utils';
import { buttonVariants } from '../button/button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps): React.JSX.Element {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      dir="ltr"
      className={cn('p-3', className)}
      classNames={{
        button: 'focusable',
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        dropdown: cn('bg-background text-foreground w-18 text-sm font-normal focusable px-1 py-0'),
        dropdown_year: 'ms-2',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(buttonVariants({ variant: 'tertiary' }), 'h-7 w-7'),
        nav_button_previous: 'absolute start-1',
        nav_button_next: 'absolute end-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex justify-between',
        head_cell: 'font-normal text-xs text-subtext w-9',
        row: 'flex w-full mt-2',
        day: cn(
          'hover:bg-secondary-background-disabled aria-selected:hover:bg-primary-background-hover',
          'h-9 w-9 text-sm',
        ),
        day_range_end: '',
        day_selected: 'bg-foreground text-background',
        day_today: 'border border-primary-border',
        day_outside: 'text-primary-disabled aria-selected:text-background',
        day_disabled: 'text-primary-disabled aria-selected:text-background',
        day_range_middle: '',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4" />,
        IconRight: () => <ChevronRight className="h-4 w-4" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
