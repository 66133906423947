import * as React from 'react';
import { cn } from '@utils';
import { Skeleton } from '../skeleton/skeleton';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & { loading?: boolean };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, className, type, loading = false, disabled, value, ...props }, ref) => {
    const input = (
      <input
        disabled={disabled || loading}
        value={loading ? undefined : value}
        type={type}
        className={cn(
          'focusable text-md block h-10 w-full border px-3 py-2',
          'file:text-primary file:text-md file:border-0 file:bg-transparent file:font-medium',
          'disabled:text-primary-disabled disabled:placeholder:text-primary-disabled disabled:border-primary-border-disabled disabled:cursor-not-allowed',
          'hover:border-primary-border-hover',
          props['aria-invalid']
            ? 'bg-error-background border-error text-error placeholder:text-error-secondary disabled:placeholder:text-error-tertiary'
            : 'bg-background border-primary-border placeholder:text-subtext text-primary',
          className,
        )}
        ref={ref}
        {...props}
        placeholder={loading ? undefined : placeholder}
      />
    );
    return loading ? (
      <div className="relative">
        {input}
        <Skeleton className="start-md absolute top-1/2 h-[1em] w-2/3 -translate-y-1/2" />
      </div>
    ) : (
      input
    );
  },
);
Input.displayName = 'Input';

export { Input };
