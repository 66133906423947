'use client';

import React, { createContext, useContext } from 'react';
import { CountriesByCode } from './get-countries-by-code';

interface CountriesByCodeProviderProps {
  countriesByCode: CountriesByCode;
  children: React.ReactNode;
}

type CountriesProviderContext = {
  countriesByCode: CountriesByCode;
};

const CountriesContext = createContext<CountriesProviderContext | null>(null);

export const CountriesProvider = ({ countriesByCode, children }: CountriesByCodeProviderProps): React.JSX.Element => {
  return <CountriesContext.Provider value={{ countriesByCode }}>{children}</CountriesContext.Provider>;
};

export const useCountriesProvider = (): CountriesProviderContext => {
  const context = useContext(CountriesContext);
  if (!context) {
    throw new Error('useCountriesByCode must be used within a CountriesByCodeProvider');
  }
  return context;
};
