'use client';
import React from 'react';
import { BackButton } from '@design-system';
import { Breadcrumbs } from '@design-system';

export type BreadcrumbProps = {
  children: React.ReactNode;
  homeUrl?: string;
  className?: string;
};

export function ResponsiveBreadcrumbs({ children, homeUrl, className }: BreadcrumbProps): React.JSX.Element {
  const allChildren = React.Children.toArray(children);

  return (
    <>
      <div className="desktop-only-block">
        <Breadcrumbs homeUrl={homeUrl} className={className}>
          {children}
        </Breadcrumbs>
      </div>
      <div className="mobile-only-block">
        <div className="gap-md flex items-center">
          <BackButton className="w-xxl h-xxl" />
          {Boolean(allChildren.length) && (
            <div className="[&_span]:text-primary truncate text-xl">{allChildren.at(-1)}</div>
          )}
        </div>
      </div>
    </>
  );
}
