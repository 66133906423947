'use client';

import { cn, formatBytes } from '@utils';
import { FileText, X } from 'lucide-react';
import Image from 'next/image';
import { Button } from '../button/button';

interface FilesPreviewProps {
  files: File[];
  onRemove: (index: number) => void;
  className?: string;
}

export const FilesPreview = ({ files, onRemove, className }: FilesPreviewProps): React.JSX.Element => {
  return (
    <div className={cn('flex max-h-48 flex-col gap-4', className)}>
      {files?.map((file: any, index: number) => (
        <FilePreviewCard key={index} file={file} onRemove={() => onRemove(index)} />
      ))}
    </div>
  );
};

interface FilePreviewCardProps {
  file: File;
  onRemove: () => void;
}

const FilePreviewCard = ({ file, onRemove }: FilePreviewCardProps): React.JSX.Element => {
  return (
    <div className="space-x-xxs relative flex w-full items-center rtl:space-x-reverse">
      <FilePreview file={file} />
      <div className="flex flex-1 flex-col gap-2 overflow-hidden">
        <div className="flex flex-col gap-px">
          <p className="text-primary leading-sm truncate text-sm font-medium">{file.name}</p>
          <p className="text-subtext leading-xs text-xs">{formatBytes(file.size)}</p>
        </div>
      </div>
      <Button type="button" variant="tertiary" onClick={onRemove} className="!p-xxs ms-xs shrink-0">
        <X size="16" />
      </Button>
    </div>
  );
};

export const isFileWithPreview = (file: File): file is File & { preview: string } => {
  return 'preview' in file && typeof file.preview === 'string';
};

interface FilePreviewProps {
  file: File;
}

const FilePreview = ({ file }: FilePreviewProps): React.JSX.Element => {
  if (isFileWithPreview(file) && file.type.startsWith('image/')) {
    return (
      <Image
        src={file.preview}
        alt={file.name}
        width={48}
        height={48}
        loading="lazy"
        className="aspect-square shrink-0 rounded-sm object-cover"
      />
    );
  }

  return <FileText className="text-primary size-10 stroke-1" aria-hidden="true" />;
};
