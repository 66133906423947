'use client';

import * as React from 'react';
import { Upload } from 'lucide-react';
import { FileRejection, useDropzone, type DropzoneProps } from 'react-dropzone';

import { useClientTranslations } from '@core-systems/i18n';
import { cn, formatBytes } from '@utils';
import { useToast } from '../toast/use-toast';
import { FilesPreview, isFileWithPreview } from './files-preview';

interface FileUploadProps extends TranslatedFileUploadProps {
  /**
   * Error message for multiple files.
   * @type string
   * @default undefined
   * @example errorMessageMultipleFiles="You can only upload one file at a time"
   */
  'errorMessageMultipleFiles': string;

  /**
   * Error message for maximum files.
   * @type string
   * @default undefined
   * @example errorMessageMaxFiles="You can only upload 4 files"
   */
  'errorMessageMaxFiles': string;

  /**
   * Tip message for maximum uploadable files.
   * @type string
   * @default undefined
   * @example messageMaxUploadableFiles="You can upload up to 4 files"
   */
  'messageMaxUploadableFiles': string;

  /**
   * Tip message for maximum uploadable size.
   * @type string
   * @default undefined
   * @example messageMaxUploadableSize="File size limit is 2MB"
   */
  'messageMaxUploadableSize': string;

  /**
   * Test id for testing
   * @type string
   * @default undefined
   * @example data-testid="page-file-cropper"
   */
  'data-testid'?: string;
}

const DEFAULT_MAX_SIZE = 1024 * 1024 * 2;
const DEFAULT_MAX_FILES = 1;

export const FileUpload = (props: FileUploadProps): React.JSX.Element => {
  const { toast } = useToast();

  const {
    value: files,
    onChange,
    accept = {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    maxSize = DEFAULT_MAX_SIZE,
    maxFiles = DEFAULT_MAX_FILES,
    multiple = false,
    disabled = false,
    placeholder,
    errorMessageMultipleFiles,
    errorMessageMaxFiles,
    messageMaxUploadableFiles,
    messageMaxUploadableSize,
    withPreview = true,
    withTips = true,
    className,
  } = props;
  const isDisabled = disabled || (files?.length ?? 0) >= maxFiles;

  const handleFilesUpdate = React.useCallback(
    (updatedListOfFiles: File[]): void => {
      onChange({ target: { value: updatedListOfFiles } } as unknown as React.FormEvent<HTMLInputElement>);
    },
    [onChange],
  );

  const onDrop = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (!multiple && maxFiles === 1 && acceptedFiles.length > 1) {
        toast({
          variant: 'error',
          title: errorMessageMultipleFiles,
        });
        return;
      }

      if ((files?.length ?? 0) + acceptedFiles.length > maxFiles) {
        toast({
          variant: 'error',
          title: errorMessageMaxFiles,
        });
        return;
      }

      rejectedFiles.map((rejectedFile) =>
        rejectedFile.errors.map((error) => toast({ variant: 'error', title: error.message })),
      );

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      const updatedFiles = files ? [...files, ...newFiles] : newFiles;

      handleFilesUpdate(updatedFiles);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files, maxFiles, multiple, handleFilesUpdate],
  );

  const onRemove = (index: number): void => {
    if (!files) return;
    const newFiles = files.filter((_: any, i: number) => i !== index);
    handleFilesUpdate(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
    multiple: maxFiles > 1 || multiple,
    disabled: isDisabled,
  });

  // Revoke preview url when component unmounts
  React.useEffect(() => {
    return (): void => {
      if (!files) return;
      files.forEach((file: any) => {
        if (isFileWithPreview(file)) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cn('relative flex w-full flex-col', className)}>
      <div
        {...getRootProps()}
        className={cn(
          'bg-background focusable border-primary-border placeholder:text-subtext text-subtext border-1 group relative grid h-52 w-full cursor-pointer place-items-center overflow-hidden border-dashed px-5 py-2.5 text-center transition',
          'hover:border-primary-border-hover hover:text-primary',
          isDragActive && !isDisabled && 'border-primary-border-hover',
          isDisabled &&
            'text-primary-disabled placeholder:text-primary-disabled border-primary-border-disabled hover:border-primary-border-disabled hover:text-primary-disabled cursor-not-allowed',
          props['aria-invalid'] &&
            'bg-error-background border-error text-error placeholder:text-error-secondary disabled:placeholder:text-error-tertiary',
        )}
        tabIndex={0}
      >
        <input {...getInputProps()} data-testid={props['data-testid']} />
        {isDragActive ? (
          <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
            <div className="p-xs rounded-full">
              <Upload className="size-7" aria-hidden="true" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
            <div className="p-xs rounded-full">
              <Upload className="size-7" aria-hidden="true" />
            </div>
            {placeholder && (
              <div className="flex flex-col gap-px">
                <p>{placeholder}</p>
              </div>
            )}
          </div>
        )}
      </div>
      {withTips && (
        <div className="text-subtext leading-xs mt-xxs text-xs">
          {maxFiles > 1 && <p>{messageMaxUploadableFiles}</p>}
          <p>{messageMaxUploadableSize}</p>
        </div>
      )}
      {files?.length && withPreview ? <FilesPreview className="mt-sm" files={files} onRemove={onRemove} /> : null}
    </div>
  );
};

interface TranslatedFileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The value of the uploader.
   * @type File[]
   * @default undefined
   * @example value={files}
   */
  value: File[];

  /**
   * Callback fired when the value changes.
   * @type (files: File[]) => void
   * @default undefined
   * @example onValueChange={(files) => console.log(files)}
   */
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;

  /**
   * Accepted file types for the uploader.
   * @type { [key: string]: string[]}
   * @default
   * ```ts
   * { "image/*": [] }
   * ```
   * @example accept={["image/png", "image/jpeg"]}
   */
  accept?: DropzoneProps['accept'];

  /**
   * Maximum file size for the uploader.
   * @type number | undefined
   * @default 1024 * 1024 * 2 // 2MB
   * @example maxSize={1024 * 1024 * 2} // 2MB
   */
  maxSize?: DropzoneProps['maxSize'];

  /**
   * Maximum number of files for the uploader.
   * @type number | undefined
   * @default 1
   * @example maxFiles={4}
   */
  maxFiles?: DropzoneProps['maxFiles'];

  /**
   * Whether the uploader should accept multiple files.
   * @type boolean
   * @default false
   * @example multiple
   */
  multiple?: boolean;

  /**
   * Placeholder for the uploader.
   * @type string
   * @default undefined
   * @example placeholder="Drag & drop some files here, or click to select files"
   */
  placeholder?: string;

  /**
   * Whether tips relative to number of files and max size are displayed.
   * @type boolean
   * @default true
   * @example withTips=true
   */
  withTips?: boolean;

  /**
   * Whether the uploader should show a preview of the file.
   * @type boolean
   * @default true
   * @example withPreview=true
   */
  withPreview?: boolean;

  /**
   * Whether the uploader is disabled.
   * @type boolean
   * @default false
   * @example disabled
   */
  disabled?: boolean;
}

export const TranslatedFileUpload = (props: TranslatedFileUploadProps): React.JSX.Element => {
  const { t } = useClientTranslations('common');
  return (
    <FileUpload
      errorMessageMultipleFiles={t('file-upload.errors.multiple-files')}
      errorMessageMaxFiles={t('file-upload.errors.max-files', { maxFiles: props.maxFiles ?? DEFAULT_MAX_FILES })}
      messageMaxUploadableFiles={t('file-upload.tips.max-files', { maxFiles: props.maxFiles ?? DEFAULT_MAX_FILES })}
      messageMaxUploadableSize={t('file-upload.tips.max-size', {
        maxSize: formatBytes(props.maxSize ?? DEFAULT_MAX_SIZE),
      })}
      {...props}
    />
  );
};
